import { deeplink } from '@app/services/deeplinks/deeplinks.service.lib'

export const emailVerifyDeeplink = deeplink({
    path: '/email/verify',
    handler: async ({ url, router }) => router.navigate(['/auth/email-verification'], {
        queryParams: {
            urlBase64: btoa(url),
        },
    }),
})
