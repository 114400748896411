import { NgModule } from '@angular/core'
import { PkceLoginService } from '@app/domains/pkce/services/pkce-login/pkce-login.service'
import { PKCE_REDIRECTOR, pkceRedirectorFactory } from '@app/domains/pkce/providers/pkce-redirector.provider'
import { PKCE_CALLBACK_URI, pkceCallbackUriFactory } from '@app/domains/pkce/providers/pkce-callback-url.provider'
import { Platform } from '@ionic/angular'
import { CountryService } from '@app/services/country/country.service'

@NgModule({
    providers: [
        PkceLoginService,
        {
            provide: PKCE_REDIRECTOR,
            deps: [Platform],
            useFactory: pkceRedirectorFactory,
        },
        {
            provide: PKCE_CALLBACK_URI,
            deps: [Platform, CountryService],
            useFactory: pkceCallbackUriFactory,
        },
    ],
})
export class PkceDomainModule {
}
