import { HttpClient, HttpStatusCode } from '@angular/common/http'
import { DestroyRef, Injectable } from '@angular/core'
import { SafeGuardPath, SafeGuardsService } from '@app/services/safe-guards/safe-guards.service'
import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    fromEvent,
    fromEventPattern,
    merge,
    of,
    switchMap,
    timer,
} from 'rxjs'
import type { RequiresInitialization } from '@app/types/framework.types'
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators'
import { CountryService } from '@app/services/country/country.service'
import { identity, isNil, not } from 'ramda'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Env } from '@env/environment.types'
import { apiOrigin } from '@lib/env/env.lib'
import { Network } from '@capacitor/network'

@Injectable({
    providedIn: 'root',
})
export class ConnectionService implements RequiresInitialization {

    /**
     * Indicates whether the network is online over time.
     */
    private networkOnline$$ = new BehaviorSubject<boolean>(true)

    /**
     * Indicates whether the target API is online over time.
     */
    private apiOnline$$ = new BehaviorSubject<boolean>(true)

    /**
     * A redirect queue for network status changes so that we never interfere with a pending navigation
     * that cannot (and should not) be cancelled from this context.
     */
    private pendingRedirect?: Promise<unknown>


    constructor(
        private readonly countryService: CountryService,
        private readonly http: HttpClient,
        private readonly safeGuardsService: SafeGuardsService,
        private readonly onDestroy: DestroyRef,
    ) {}


    public async initialize(): Promise<void> {
        merge(
            fromEvent(window, 'offline'),
            fromEvent(window, 'online'),
            fromEventPattern((handler) => Network.addListener('networkStatusChange', handler)),
        ).pipe(
            map(() => this.networkIsOnline()),
            startWith(this.networkIsOnline()),
            distinctUntilChanged(),
            takeUntilDestroyed(this.onDestroy),
        ).subscribe(this.networkOnline$$)

        combineLatest([
            this.countryService.countryCode$,
            this.networkOnline$$,
        ]).pipe(
            switchMap(([countryCode, networkOnline]) => {
                return isNil(countryCode) || not(networkOnline)
                    ? of(false)
                    : timer(0, 15000).pipe(switchMap(() => this.testApiAvailability(countryCode)))
            }),
            distinctUntilChanged(),
            takeUntilDestroyed(this.onDestroy),
        ).subscribe(this.apiOnline$$)

        this.networkOnline$$.subscribe((networkOnline) => this.queue(() => {
            return networkOnline
                ? this.safeGuardsService.attemptIntendedUrl()
                : this.safeGuardsService.redirectToGuard(SafeGuardPath.NoNetworkConnection)
        }))
    }

    // ------------------------------------------------------------------------------
    //      Public network status checks
    // ------------------------------------------------------------------------------

    public networkIsOffline(): boolean {
        return ! navigator.onLine
    }

    public networkIsOnline(): boolean {
        return navigator.onLine
    }

    public async awaitNetworkOnline(): Promise<void> {
        await firstValueFrom(
            this.networkOnline$$.pipe(
                filter(identity),
            ),
        )
    }

    // ------------------------------------------------------------------------------
    //      Public API availability checks
    // ------------------------------------------------------------------------------

    public apiIsOnline(): boolean {
        return this.apiOnline$$.getValue()
    }

    public apiIsOffline(): boolean {
        return ! this.apiOnline$$.getValue()
    }

    public async awaitApiOnline(): Promise<void> {
        await firstValueFrom(
            this.apiOnline$$.pipe(
                filter(identity),
            ),
        )
    }

    // ------------------------------------------------------------------------------
    //      Private methods
    // ------------------------------------------------------------------------------

    private async testApiAvailability(countryCode: Env.CountryCode): Promise<boolean> {
        try {
            const result = await firstValueFrom(this.http.head(apiOrigin(countryCode), {
                responseType: 'text',
                observe: 'response',
            }))

            return result.status === HttpStatusCode.Ok
        } catch (err) {
            console.warn(err)
            return false
        }
    }

    private async queue(fn: () => Promise<unknown>): Promise<void> {
        await this.pendingRedirect
        await (this.pendingRedirect = fn())
        this.pendingRedirect = undefined
    }
}
