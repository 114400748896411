import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Platform } from '@ionic/angular'
import { Browser } from '@capacitor/browser'

@Component({
    selector: 'app-user-consent-checkbox',
    templateUrl: './user-consent-checkbox.component.html',
    styleUrls: ['./user-consent-checkbox.component.scss'],
})
export class UserConsentCheckboxComponent {

    @Input({ required: true })
    public control: FormControl<boolean>

    constructor(
        private readonly platform: Platform,
    ) {
    }

    public openTermsOfUse(): void {
        this.openLink('https://joinprogram.com/terms-of-use/')
    }

    public openPrivacyPolicy(): void {
        this.openLink('https://joinprogram.com/privacy-policy/')
    }

    private openLink(url: string): void {
        // Todo: https://gitlab.pxlwidgets.com/clients/papageno/dito-app/-/blob/master/src/app/services/navigation/external-link.service.ts?ref_type=heads
        if (this.platform.is('hybrid')) {
            void Browser.open({ url, toolbarColor: '#51c796' })
        } else {
            window.open(url, '_blank')
        }
    }
}
