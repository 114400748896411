import { Component } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { formatDateTime } from '@lib/date-time/date-time.lib'
import { Router } from '@angular/router'
import { UserService } from '@app/services/user/user.service'

@Component({
    selector: 'app-consent-policies',
    templateUrl: './consent-policies.component.html',
    styleUrls: ['./consent-policies.component.scss'],
})
export class ConsentPoliciesComponent {

    public form = this.fb.group({
        consent: this.fb.nonNullable.control<boolean>(false, [
            Validators.requiredTrue,
        ]),
    })

    public isLoading: boolean = false

    constructor(
        private readonly fb: FormBuilder,
        private readonly userService: UserService,
        private readonly router: Router,
    ) {
    }

    public async finishConsent() {
        this.isLoading = true

        try {
            await this.userService.updateUser({
                privacyPolicyAcceptedAt: formatDateTime(),
                termsAcceptedAt: formatDateTime(),
            })

            await this.router.navigate(['/home'])
        } catch {
            //todo

        } finally {
            this.isLoading = false
        }
    }
}
