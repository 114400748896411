import { HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { GraphQLModule } from '@app/modules/graphql/graphql.module'
import { CartService } from '@app/services/cart/cart.service'
import { UserService } from '@app/services/user/user.service'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage-angular'
import { TranslateModule } from '@ngx-translate/core'
import { BootService } from '@app/services/boot/boot.service'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { WrappersDomainModule } from '@app/domains/wrappers/wrappers-domain.module'
import { ionicModuleConfig } from '@app/config/ionic-module.config'
import { translateModuleConfig } from '@app/config/translate-module.config'
import { ionicStorageModuleConfig } from '@app/config/ionic-storage-module.config'
import { PkceDomainModule } from '@app/domains/pkce/pkce-domain.module'
import { UiDomainModule } from '@app/domains/ui/ui-domain.module'

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        IonicStorageModule.forRoot(ionicStorageModuleConfig),
        IonicModule.forRoot(ionicModuleConfig),
        AppRoutingModule,
        TranslateModule.forRoot(translateModuleConfig),
        GraphQLModule,
        HttpClientModule,
        BrowserAnimationsModule,
        WrappersDomainModule,
        PkceDomainModule,
        UiDomainModule,
    ],
    providers: [
        CartService,
        UserService,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        {
            // https://github.com/bugsnag/bugsnag-js/issues/2057 Cannot use esbuild yet...
            provide: ErrorHandler,
            useFactory: () => new BugsnagErrorHandler(),
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (bootService: BootService) => () => bootService.bootApplication(),
            deps: [BootService],
            multi: true,
        },
    ],
})
export class AppModule {
}
