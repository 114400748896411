import { Injectable, Injector, NgZone } from '@angular/core'
import Bugsnag from '@bugsnag/browser'
import { Router } from '@angular/router'
import { Platform } from '@ionic/angular'
import type { RequiresInitialization } from '@app/types/framework.types'
import { App } from '@capacitor/app'
import { DeeplinkHandler, DeeplinkMatcher } from './deeplinks.service.lib'
import { passwordResetDeeplink } from './links/password-reset-deeplink'
import { emailVerifyDeeplink } from './links/email-verify-deeplink'
import { checkoutKickbackDeeplink } from './links/checkout-kickback-deeplink'
import { orderPickupDeeplink } from './links/order-pickup-deeplink'
import { pkceCallbackDeeplink } from './links/pkce-callback-deeplink'

@Injectable({
    providedIn: 'root',
})
export class DeeplinksService implements RequiresInitialization {

    private readonly matchers: readonly DeeplinkMatcher[] = [
        passwordResetDeeplink,
        emailVerifyDeeplink,
        checkoutKickbackDeeplink,
        orderPickupDeeplink,
        pkceCallbackDeeplink,
    ]

    constructor(
        private readonly router: Router,
        private readonly injector: Injector,
        private readonly platform: Platform,
        private readonly zone: NgZone,
    ) {
    }

    public async initialize(): Promise<void> {
        if (! this.platform.is('hybrid')) {
            return
        }

        await App.addListener('appUrlOpen', (event) => this.zone.run(() => {
            const handler = this.match(event.url)

            if (handler) {
                return this.runHandler(handler, event.url)
            }

            // At this point none of the matchers provided a handler:
            console.error('Unmatched Deeplink', { event })
            Bugsnag.leaveBreadcrumb('Unmatched Deeplink', event, 'error')
            Bugsnag.notify('No match found for activated Deeplink')
        }))
    }

    private match(url: string): DeeplinkHandler | null {
        for (const matcher of this.matchers) {
            const handler = matcher(url)

            if (handler) {
                return handler
            }
        }

        return null
    }

    private async runHandler(handler: DeeplinkHandler, url: string): Promise<void> {
        try {
            const success = await handler(this.router, this.injector)

            if (! success) {
                Bugsnag.leaveBreadcrumb('Deeplink handler reported unsuccessful', { url })
                Bugsnag.notify('Deeplink handler reported unsuccessful')
            }
        } catch (error: any) {
            Bugsnag.leaveBreadcrumb('Error while running Deeplink handler', undefined, 'error')
            Bugsnag.notify(error)
        }
    }
}
