import { Injectable } from '@angular/core'
import {
    AppCompatibilityStatusFragment,
    CompatibilityQueryService,
    LatestAppVersionsFragment,
    LatestAppVersionsQueryService,
} from '@app-graphql/api-schema'
import { Platform } from '@ionic/angular'
import { environment } from '@env/environment'
import semver from 'semver'
import Bugsnag from '@bugsnag/js'
import { APIVersionCheckError } from '@app/errors/APIVersionCheckError'
import { ApiCompatibilityStatus } from '@app/services/platform-versions/platform-versions.service.types'
import { always, isNil } from 'ramda'
import packageJson from '@root/package.json'
import gitRev from '@root/.git-rev.json'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import { cached, minutes } from '@app/decorators/method/cached.decorator'
import { map } from 'rxjs/operators'
import { Storage } from '@ionic/storage'
import { NativeMarket } from '@capacitor-community/native-market'

@Injectable({
    providedIn: 'root',
})
export class PlatformVersionsService {

    public readonly IOS_APP_ID = '1588911469'
    public readonly ANDROID_APP_ID = 'com.pxlwidgets.join.program'
    public readonly APP_VERSION_STORAGE_KEY = 'app:version'

    private readonly updateAvailableVisibleSubject$ = new BehaviorSubject<boolean>(false)
    public readonly updateAvailableVisible$ = this.updateAvailableVisibleSubject$.asObservable()

    constructor(
        private readonly compatibilityQueryService: CompatibilityQueryService,
        private readonly latestAppVersionsQueryService: LatestAppVersionsQueryService,
        private readonly storage: Storage,
        private readonly platform: Platform,
    ) {
    }

    public getAppVersion(): string {
        return packageJson.version ?? ''
    }

    private currentAppVersionsIsLatest =
        (appVersion: string, newestAppVersions: LatestAppVersionsFragment): boolean => {
            const currentAppVersion = semver.coerce(appVersion)
            const newestAndroidVersion = semver.coerce(newestAppVersions.android)
            const newestIosVersion = semver.coerce(newestAppVersions.ios)

            if (isNil(currentAppVersion) || isNil(newestAndroidVersion) || isNil(newestIosVersion)) {
                return true
            }

            return this.platform.is('ios') ? semver.gte(currentAppVersion, newestIosVersion)
                : semver.gte(currentAppVersion, newestAndroidVersion)
        }

    public async checkForNewUpdates(): Promise<void> {
        const appVersion = this.getAppVersion()
        const newestAppVersions = await this.getNewestAppVersions()
        const newestAppVersionForPlatform =
            this.platform.is('ios') ? newestAppVersions.ios : newestAppVersions.android
        const ignoredUpdate = await this.storage.get('update-dismissed')


        if (ignoredUpdate === newestAppVersionForPlatform) {
            return
        }

        if (! this.currentAppVersionsIsLatest(appVersion, newestAppVersions)) {
            this.updateAvailableVisibleSubject$.next(true)
        }
    }

    public async hideAppUpdate(): Promise<void> {
        this.updateAvailableVisibleSubject$.next(false)
    }

    public async getNewestAppVersions(): Promise<LatestAppVersionsFragment> {
        try {
            return await firstValueFrom(this.latestAppVersionsQueryService.fetch().pipe(
                map((result) => result.data.latestAppVersions),
            ))
        } catch (error) {
            return {
                android: null,
                ios: null,
            }
        }
    }

    public getGitRevision(): string {
        return gitRev.rev
    }

    public async getAppCompatibility(): Promise<AppCompatibilityStatusFragment | null> {
        try {
            return await firstValueFrom(
                this.compatibilityQueryService.fetch({
                    appVersion: this.getAppVersion(),
                    supportedApiRange: environment.apiAcceptedVersionRange,
                }).pipe(
                    map((x) => x.data.appCompatibility),
                ),
            )
        } catch (error) {
            return null
        }
    }

    @cached({
        KEY: always('status'),
        TTL: minutes(10),
    })
    public async getApiCompatibilityStatus(): Promise<ApiCompatibilityStatus> {
        try {
            const compatibility = await this.getAppCompatibility()

            if (isNil(compatibility)) {
                return ApiCompatibilityStatus.Unknown
            }

            if (compatibility.compatible) {
                return ApiCompatibilityStatus.InRange
            }

            if (! compatibility.appSupportsApi) {
                const actualVersion = new semver.SemVer(compatibility.apiVersion)
                const acceptedRange = new semver.Range(environment.apiAcceptedVersionRange)

                if (semver.gtr(actualVersion, acceptedRange)) {
                    return ApiCompatibilityStatus.GtRange
                }

                if (semver.ltr(actualVersion, acceptedRange)) {
                    return ApiCompatibilityStatus.LtRange
                }
            }

            if (! compatibility.apiSupportsApp) {
                return ApiCompatibilityStatus.GtRange
            }

            return ApiCompatibilityStatus.Unknown
        } catch (error) {
            this.logSemverCheckError(APIVersionCheckError.lift(error))
            return ApiCompatibilityStatus.Unknown
        }
    }

    public async openAppStore(): Promise<void> {
        if (this.platform.is('android')) {
            await NativeMarket.openStoreListing({ appId: this.ANDROID_APP_ID })
        } else if (this.platform.is('ios')) {
            await NativeMarket.openStoreListing({ appId: this.IOS_APP_ID })
        }
    }

    private logSemverCheckError(error: APIVersionCheckError): void {
        if (this.looksLikeDevelopmentEnvironment()) {
            console.log(error)
        }

        Bugsnag.notify(error)
    }

    private looksLikeDevelopmentEnvironment(): boolean {
        return this.platform.is('desktop')
            || this.platform.is('mobileweb')
    }
}
