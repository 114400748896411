import { DestroyRef, Injectable } from '@angular/core'
import {
    FeedFiltersQuery,
    FeedFiltersQueryService,
    ProductCategory,
    RestaurantFragment,
    RestaurantTypeEnum,
} from '@app-graphql/api-schema'
import { isNil } from 'ramda'
import { BehaviorSubject, combineLatest, filter, firstValueFrom, map, Observable } from 'rxjs'
import { AuthService } from '@app/services/auth/auth.service'
import { connectObservable, distinctUntilChangedEquals } from '@lib/rxjs/rxjs.lib'
import { FeedFilter } from './feed-filter'
import { filterMap } from '@lib/array/array.lib'
import { switchMap } from 'rxjs/operators'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Injectable({
    providedIn: 'root',
})
export class FeedFilterService {

    /**
     * It will emit only the filter that has been applied.
     */
    public readonly filterState$: Observable<FeedFilter>
    /**
     * It will emit all the filter states, even if the filter has not been applied
     */
    public readonly allFilterStates$: Observable<FeedFilter>

    /**
     * It will emit all the filter states, even if the filter has not been applied
     *
     * @remarks It is private because it should not be used outside of this service.
     */
    private readonly state$: BehaviorSubject<FeedFilter> = new BehaviorSubject<FeedFilter>(FeedFilter.empty())

    /**
     * It will emit the selected restaurant type.
     */
    private readonly selectedRestaurantType$: BehaviorSubject<RestaurantTypeEnum> =
        new BehaviorSubject<RestaurantTypeEnum>(RestaurantTypeEnum.Restaurant)

    constructor(
        private readonly destroy: DestroyRef,
        private readonly authService: AuthService,
        private readonly feedFiltersQueryService: FeedFiltersQueryService,
    ) {
        const onlyChangedStates$ = this.state$.pipe(distinctUntilChangedEquals())
        this.filterState$ = connectObservable(onlyChangedStates$.pipe(filter((state) => state.isFilterApplied())))
        this.allFilterStates$ = connectObservable(onlyChangedStates$)
    }

    /**
     * It subscribes tu user$ and it will fetch the feed filters if there is a user and the user has a location.
     */
    public async initialize(): Promise<void> {
        combineLatest([
            this.selectedRestaurantType$,
            this.authService.user$,
        ]).pipe(
            switchMap(([selectedRestaurantType, user]) => {
                return isNil(user) || isNil(user.location) || isNil(user.client)
                    ? Promise.resolve(FeedFilter.empty())
                    : this.createFeedFilter(user.client.id, user.location.id, selectedRestaurantType)
            }),
            takeUntilDestroyed(this.destroy),
        )
            .subscribe((filters) => this.state$.next(filters))
    }

    // ------------------------------------------------------------------------------
    //      Generic filter functions
    // ------------------------------------------------------------------------------

    /**
     *
     * @returns an observable of the restaurant type.
     */
    public getSelectedRestaurantType(): Observable<RestaurantTypeEnum> {
        return this.selectedRestaurantType$.asObservable()
    }

    /**
     *
     * @param restaurantType - The restaurant type to be selected
     *
     * It changes the selected restaurant type.
     */
    public changeSelectedRestaurantType(restaurantType: RestaurantTypeEnum): void {
        this.selectedRestaurantType$.next(restaurantType)
    }

    /**
     * It gets the last state and sets isApplied to true.
     */
    public applyLastFilter(): void {
        this.state$.next(this.getCurrentState().changeIsApplied(true))
    }

    /**
     * It sets the lastApplied state again as a current state
     * if the current state is not already applied.
     *
     * @returns a void promise to wait until the state is applied if needed
     *
     */
    public async resendLastAppliedState(): Promise<void> {
        const currentState = this.getCurrentState()
        if (currentState.isFilterApplied()) return
        const lastAppliedState = await this.getLastAppliedState()
        this.state$.next(lastAppliedState)
    }

    /**
     * It returns the last applied state.
     *
     * @returns a promise with the last applied state
     *
     */
    public getLastAppliedState(): Promise<FeedFilter> {
        return firstValueFrom(this.filterState$)
    }

    /**
     * Returns a copy of the current state.
     *
     * @returns The a copy of the current state
     *
     */
    public getCurrentState(): FeedFilter {
        return this.state$.getValue()
    }

    /**
     * Clears the state filters
     *
     * @param isApplied - By default false, if you want to emit this change immediately set it to true
     *
     * @remarks
     * See that is not removing the categories and restaurants from the state,
     * it is just setting the isSelected to false.
     *
     */
    public clearFilters(isApplied: boolean = false): void {
        this.state$.next(this.getCurrentState().uncheckAll().changeIsApplied(isApplied))
    }

    // ------------------------------------------------------------------------------
    //      Only show office days functions
    // ------------------------------------------------------------------------------

    /**
     * It changes the OnlyShowOfficeDays property of the category to false.
     *
     */
    public uncheckOnlyShowOfficeDays(): void {
        this.state$.next(this.getCurrentState().changeOnlyShowOfficeDays(false))
    }

    /**
     * It changes the OnlyShowOfficeDays property of the category to true.
     *
     */
    public checkOnlyShowOfficeDays(): void {
        this.state$.next(this.getCurrentState().changeOnlyShowOfficeDays(true))
    }

    // ------------------------------------------------------------------------------
    //      Dietary Preferences functions
    // ------------------------------------------------------------------------------

    /**
     * It changes the isSelected property of the DietaryClass with the given id to true.
     *
     * @param dietaryKey - The key of the dietary to be selected
     * @param isApplied - By default false, if you want to emit this change immediately set it to true
     *
     */
    public checkDietaryClass(dietaryKey: string, isApplied: boolean = false): void {
        this.state$.next(this.changeDietaryClassSelection(dietaryKey, true, isApplied))
    }

    /**
     * It changes the isSelected property of the DietaryClass with the given id to false.
     *
     * @param dietaryKey - The key of the DietaryClass to be unselected
     * @param isApplied - By default false, if you want to emit this change immediately set it to true
     *
     */
    public uncheckDietaryClass(dietaryKey: string, isApplied: boolean = false): void {
        this.state$.next(this.changeDietaryClassSelection(dietaryKey, false, isApplied))
    }

    /**
     * It change the property isSelected to false of all DietaryClasses.
     */
    public uncheckAllDietaryClasses(): void {
        this.state$.next(this.getCurrentState().uncheckAllDietaryClasses())
    }

    /**
     * It selects or unselects the DietaryClass by the given id.
     *
     * @param dietaryKey - The key of the DietaryClass to be changed
     * @param isSelected - It has to be true or false, depending if you want to select or unselect the DietaryClass
     * @param isApplied - By default false, if you want to emit this change immediately set it to true
     *
     */
    private changeDietaryClassSelection(
        dietaryKey: string,
        isSelected: boolean,
        isApplied: boolean = false,
    ): FeedFilter {
        return this.getCurrentState().changeDietaryClass(dietaryKey, isSelected).changeIsApplied(isApplied)
    }

    // ------------------------------------------------------------------------------
    //      Product Category functions
    // ------------------------------------------------------------------------------

    /**
     * It changes the isSelected property of the category with the given id to true.
     *
     * @param categoryId - The id of the category to be selected
     * @param isApplied - By default false, if you want to emit this change immediately set it to true
     *
     */
    public checkProductCategory(categoryId: ProductCategory['id'], isApplied: boolean = false): void {
        this.state$.next(this.changeProductCategorySelection(categoryId, true, isApplied))
    }

    /**
     * It changes the isSelected property of the category with the given id to false.
     *
     * @param categoryId - The id of the category to be unselected
     * @param isApplied - By default false, if you want to emit this change immediately set it to true
     *
     */
    public uncheckProductCategory(categoryId: ProductCategory['id'], isApplied: boolean = false): void {
        this.state$.next(this.changeProductCategorySelection(categoryId, false, isApplied))
    }

    /**
     * It change the property isSelected to false of all product categories.
     */
    public uncheckAllProductCategories(): void {
        this.state$.next(this.getCurrentState().uncheckAllCategories())
    }

    /**
     * It selects or unselects the category by the given id.
     *
     * @param categoryId - The id of the category to be changed
     * @param isSelected - It has to be true or false, depending if you want to select or unselect the category
     * @param isApplied - By default false, if you want to emit this change immediately set it to true
     *
     */
    private changeProductCategorySelection(
        categoryId: ProductCategory['id'],
        isSelected: boolean,
        isApplied: boolean = false,
    ): FeedFilter {
        return this.getCurrentState().changeProductCategorySelection(categoryId, isSelected).changeIsApplied(isApplied)
    }

    // ------------------------------------------------------------------------------
    //      Restaurant functions
    // ------------------------------------------------------------------------------

    /**
     *
     * @returns an observable of the selected restaurants.
     * If no restaurant is selected it returns all the available restaurants.
     */
    public getSelectedRestaurants(): Observable<RestaurantFragment[]> {
        return this.filterState$.pipe(map((state) => state.toRestaurantsSelected()))
    }

    /**
     * It changes the isSelected property of the restaurant with the given id to true.
     *
     * @param restaurantId - The id of the restaurant to be selected
     * @param isApplied - By default false, if you want to emit this change immediately set it to true
     *
     */
    public checkRestaurant(restaurantId: RestaurantFragment['id'], isApplied: boolean = false): void {
        this.state$.next(this.changeRestaurantSelection(restaurantId, true, isApplied))
    }

    /**
     * It changes the isSelected property of the restaurant with the given id to false.
     *
     * @param restaurantId - The id of the restaurant to be unselected
     * @param isApplied - By default false, if you want to emit this change immediately set it to true
     *
     */
    public uncheckRestaurant(restaurantId: RestaurantFragment['id'], isApplied: boolean = false): void {
        this.state$.next(this.changeRestaurantSelection(restaurantId, false, isApplied))
    }

    /**
     * It change the property isSelected to false of all restaurants.
     */
    public clearRestaurants(): void {
        this.state$.next(this.getCurrentState().uncheckAllRestaurants())
    }

    /**
     * It selects or unselects the category by the given id.
     *
     * @param restaurantId - The id of the restaurant to be changed
     * @param isSelected - It has to be true or false, depending if you want to select or unselect the category
     * @param isApplied - By default false, if you want to emit this change immediately set it to true
     *
     */
    private changeRestaurantSelection(
        restaurantId: RestaurantFragment['id'],
        isSelected: boolean,
        isApplied: boolean = false,
    ): FeedFilter {
        return this.getCurrentState().changeRestaurantSelection(restaurantId, isSelected).changeIsApplied(isApplied)
    }

    private async fetchFeedFilters(clientID: string, locationID: string): Promise<FeedFiltersQuery> {
        const result = await firstValueFrom(this.feedFiltersQueryService.fetch({ clientID, locationID }))
        return result.data
    }

    /**
     * It fetches and change the state with the fetched feed filters for the given location id.
     */
    private async createFeedFilter(
        clientID: string,
        locationID: string,
        selectedRestaurantType: RestaurantTypeEnum,
    ): Promise<FeedFilter> {
        const { publicCategories, restaurants, dietaryClasses } = await this.fetchFeedFilters(clientID, locationID)

        return new FeedFilter(
            locationID,
            true,
            filterMap(
                restaurants.data as RestaurantFragment[],
                ({ type }) => selectedRestaurantType === type,
                (restaurant) => ({ isSelected: false, restaurant }),
            ),
            publicCategories.data.map((category) => ({ isSelected: false, category })),
            true,
            dietaryClasses.map((dietaryClass) => ({ isSelected: false, dietaryClass })),
        )
    }
}
