import { isNil } from 'ramda'
import { Router } from '@angular/router'
import { Injector } from '@angular/core'
import { BinaryFunction, UnaryFunction } from '@app/types/common.types'

type PathParams<T, TAcc extends string = never> = T extends `${string}:${infer Param}/${infer Rest}`
    ? PathParams<Rest, TAcc | Param>
    : T extends `${string}:${infer Param}`
        ? { [K in TAcc | Param]: string }
        : { [K in TAcc]: string }


type PathMatcher<Pattern extends string> = (url: string) => PathParams<Pattern> | null

function createPathMatcher<Pattern extends string>(path: Pattern): PathMatcher<Pattern> {
    const pattern = path.replace(/:([a-zA-Z0-9_]+)/g, (_, param) => `(?<${param}>[^/]+)`)
    const regex = new RegExp(`^${pattern}$`)

    return (url) => {
        const match = url.match(regex)
        return match && (match.groups ?? {}) as PathParams<Pattern>
    }
}

interface DeeplinkMatch<Pattern extends string> {
    url: string
    params: PathParams<Pattern>
    search: URLSearchParams
}

interface HandlerParams<Pattern extends string> extends DeeplinkMatch<Pattern> {
    router: Router
    injector: Injector
}

export type DeeplinkMatcher = UnaryFunction<string, DeeplinkHandler | null>
export type DeeplinkHandler = BinaryFunction<Router, Injector, Promise<boolean>>

interface DeeplinkArgs<Pattern extends string> {
    /**
     * Specify the path pattern that matches the deeplink. Paths must start with a `/`.
     * Angular style path parameters are allowed, for example `"/some/path/:param1/constant/:param2"`.
     */
    path: Pattern

    /**
     * Provide the handler function for matching URLs. Takes the HandlerParams containing the
     * match information, and should return a Promise of a boolean indicating whether the
     * handler completed successfully.
     */
    handler: (params: HandlerParams<Pattern>) => Promise<boolean>

    /**
     * Optionally provide a matching function to perform additional checks on the parsed params.
     * By default, the existence of path params is checked only. This allows to also check search
     * params, or check for specific values of path params.
     */
    matcher?: (params: DeeplinkMatch<Pattern>) => boolean
}


export function deeplink<Pattern extends string>(args: DeeplinkArgs<Pattern>): DeeplinkMatcher {
    const pathMatcher = createPathMatcher(args.path)

    return (url) => {
        const normalizedUrl = url.replace(/^com.pxlwidgets.join.program/, 'https')
        const parsedUrl = new URL(normalizedUrl)
        const params = pathMatcher(parsedUrl.pathname)
        const search = parsedUrl.searchParams

        if (isNil(params)) {
            return null
        }

        if (args.matcher && ! args.matcher({ params, url, search })) {
            return null
        }

        return (router, injector) => args.handler({ url, params, search, router, injector })
    }
}
