import { deeplink } from '@app/services/deeplinks/deeplinks.service.lib'

export const passwordResetDeeplink = deeplink({
    path: '/app/reset-password/:token',
    matcher: ({ search }) => search.has('email'),
    handler: ({ params, search, router }) => router.navigate(['/auth/update-forgotten-password'], {
        queryParams: {
            email: search.get('email'),
            token: params.token,
        },
    }),
})
