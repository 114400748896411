import { Injectable } from '@angular/core'
import {
    DeleteMeMutationService,
    UpdateMeMutationService,
    UpdateUserAllergensMutationService,
    UpdateUserAllergensMutationVariables,
    UpdateUserOfficeDaysMutationService,
    UpdateUserOfficeDaysMutationVariables,
    WeekDayEnumsQueryService, UpdateMeInput,
} from '@app-graphql/api-schema'
import { ISODayNumber, isoDayNumber, ISOWeekDay, parseDateTime } from '@lib/date-time/date-time.lib'
import { AuthService } from '@app/services/auth/auth.service'
import { isNil } from 'ramda'
import { firstValueFrom } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(
        private updateMeService: UpdateMeMutationService,
        private updateUserOfficeDaysMutationService: UpdateUserOfficeDaysMutationService,
        private updateUserAllergensMutationService: UpdateUserAllergensMutationService,
        private weekDayEnumsQueryService: WeekDayEnumsQueryService,
        private deleteUserMutationService: DeleteMeMutationService,
        private authService: AuthService,
    ) {
    }

    public getNewsReadDateTime(): Date | undefined {
        const newsReadAt = this.authService.getUser()?.newsReadAt as string | undefined
        return isNil(newsReadAt) ? undefined : parseDateTime(newsReadAt)
    }

    public getWeekdays() {
        return this.weekDayEnumsQueryService.fetch()
    }

    public updateUserOfficeDays(input: UpdateUserOfficeDaysMutationVariables) {
        return this.updateUserOfficeDaysMutationService.mutate(input)
    }

    public updateUserAllergens(input: UpdateUserAllergensMutationVariables) {
        return this.updateUserAllergensMutationService.mutate(input)
    }

    public async updateUser(input: UpdateMeInput) {
        const result = await firstValueFrom(
            this.updateMeService.mutate({ input }, {
                context: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            }),
        )
        await this.authService.refreshUser()
        return result
    }

    public async deactivateUser(): Promise<void> {
        const user = this.authService.getUser()

        if (user) {
            await firstValueFrom(
                this.deleteUserMutationService.mutate(),
            )
        }
    }

    public getOfficeDays(numbersOnly: true): ISODayNumber[]
    public getOfficeDays(): ISOWeekDay[]
    public getOfficeDays(numbersOnly = false): ISOWeekDay[] | ISODayNumber[] {
        const user = this.authService.getUser()

        if (user === null) {
            return []
        }

        return numbersOnly
            ? user.officeDays.map(({ dayOfTheWeek }) => isoDayNumber(dayOfTheWeek))
            : user.officeDays.map(({ dayOfTheWeek }) => ({
                weekDay: dayOfTheWeek,
                isoDayNumber: isoDayNumber(dayOfTheWeek),
            }))
    }
}
